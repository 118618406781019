import Typography from 'Clutch/Atoms/Typography'
import Form from 'Clutch/Atoms/Form'
import Accordion from 'Clutch/Molecules/Accordion'
import { useState, useContext } from 'react'
import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'
import styles from './sortTypeFacet.module.scss'
import AppName from '../../../appName/appName'
import useProductResultPage from '/hooks/useProductResultPage'
import { usePrpStore } from '../../../contexts/prpContext'
import { setSidebarOpen } from '../../../Utilities/LocalStorage/sidebarDrawer'
import Skeleton from 'Clutch/Atoms/Skeleton'
import accordionStyles from '../shared/facetAccordion.module.scss'

const SortTypeFacet = () => {
    const [loading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const sortOrders = useProductResultPage(x => x.sortOrders)

    const Navigate = usePrpStore(x => x.Navigate)

    if (!sortOrders) {
        return <Skeleton height={40} width={250} />
    }

    const summary = (
        <Typography as={'h3'} font={'bold'} tone={'normal'} size={1} data-testid={`facetName_sort`}>
            Sort
        </Typography>
    )

    const getSortTypeOptions = () => {
        return sortOrders.map(x => { return { label: x.title, value: x.title, checked: x.isSelected } })
    }

    const navigate = (e) => {
        setSidebarOpen()
        let found = sortOrders.find(x => x.title == e.target.value);
        Navigate(e, found.url, setIsLoading);

    }

    return (
        <Accordion
            summary={summary}
            defaultOpen={open}
            fill
            iconOpened={'chevron'}
            iconClosed={'chevron'}
            segmentAccordionName={`${AppName} Sort Type Accordion`}
            size={1}
            data-testid={'facet_sort'}
            className={accordionStyles[`accordionSvgOpen_${open}`]}
            onClick={() => setOpen(!open)}>
            <CoveringSpinner isLoading={loading} opacityWhenLoading={0.5} >

                <Form.Radio id={'sortType_sidebar'} values={getSortTypeOptions()} className={styles.option} onChange={(e) => navigate(e)} />

            </CoveringSpinner>
        </Accordion>

    )
}

export default SortTypeFacet