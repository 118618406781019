import React, {useEffect, useState} from 'react';
import useProductResultPage from '../../hooks/useProductResultPage'
import Image from 'next/image'
import Html from 'Clutch/Atoms/Html'
import Typography from 'Clutch/Atoms/Typography'
import styles from './campaign.module.scss'
import ClassNames from 'classnames'
import Carousel from 'Clutch/Organisms/Carousel'

const Campaign = React.forwardRef(({placement, productCardIndex}, ref) => {
    const campaign = useProductResultPage(x => x.campaign)

    const [refHeight, setRefHeight] = useState()
    const [refWidth, setRefWidth] = useState()

    useEffect(() => {
        if (ref) {
            setRefHeight(ref?.current?.clientHeight)
            setRefWidth(ref?.current?.clientWidth)
        }
    })

    if (campaign?.campaignType !== "BannerCampaign" && placement === "embedded") {
        return campaign?.htmlText ? <div className={styles.wrapper}>
            <Html html={campaign.htmlText} className={styles.campaign}/>
        </div> : null
    }

    if (campaign?.assets === null || campaign?.assets?.length === 0) {
        return null
    }

    let assets = []
    let cardAsset = null
    let card = null

    if (placement === "top") {
        assets = campaign?.assets?.filter(x => x.placement === "top")
    } else if (placement === "embedded") {
        assets = campaign?.assets?.filter(x => x.placement === "embedded")
        const placementIndex = campaign?.campaignPositions?.campaignCardPositions?.find(x => x === productCardIndex)
        if (placementIndex) {
            let cardNum = campaign?.campaignPositions?.campaignCardPositions?.indexOf(placementIndex)
            if (cardNum > -1) {
                cardAsset = assets?.find(x => x.cards != null)
                card = cardAsset?.cards[cardNum]
            }
        }
    } else if (placement === "bottom") {
        assets = campaign?.assets?.filter(x => x.placement === "bottom")
    } else {
        if (campaign?.assets?.length > 0)
            assets = campaign?.assets
    }

    const deviceStyles = (device) => {
        if (device === "mobile") {
            return ClassNames(styles.hideDesktop, styles.campaignWrapper)
        } else if (device === "desktop") {
            return ClassNames(styles.hideMobile, styles.campaignWrapper)
        }
        return ""
    }

    const campaignClickEvent = (assetName) => {
        try {
            analytics.track('Clicked', {
                element: `prp search provider asset ${assetName}`
            })
        } catch (e) {
            console.error('analytics not defined')
        }
    }

    const imageStyleWithBorder = (border) => ({
        width: '100%',
        height: 'auto',
        border: border ? '1px solid rgba(0, 0, 0, 0.15)' : 'none',
        borderRadius: border ? '1px' : 'none'
    });

    return (
        <>
            {
                ref && placement === "embedded" && card &&
                <div className={ClassNames(styles.cardWrapper, styles.campaignCard)}
                     onClick={() => campaignClickEvent(cardAsset?.assetName)}
                     style={{height: refHeight, width: refWidth, overflow: 'hidden'}}>
                    <a href={card.href} className={styles.cardImage} rel="nofollow">
                        <Image
                            className={styles.cardImage}
                            src={card.image}
                            alt={card.imageAlt}
                            title={card.imageAlt}
                            sizes="(max-width: 799px) 799px, 1280px"
                            style={imageStyleWithBorder(card?.border)}
                            width={0}
                            height={0}
                        />
                    </a>
                </div>
            }
            <div className={ClassNames(styles.fullWidth, ref ? styles.hide : null)}>
                {!ref && (assets || []).map(asset => {
                    return (
                        (asset?.rows || []).map(row => {
                            let assetName = asset?.assetName
                            let mobileScrollable = asset.device === "mobile" && row.scrollable
                            let sections = (row.sections || []).map(section => {

                                return (
                                    <div
                                        className={mobileScrollable ? styles.carouselImageWrapper : styles.imageWrapper}
                                        onClick={() => campaignClickEvent(assetName)}>
                                        <a href={section.href} {...(section.seoFollow ? {} : {rel: 'nofollow'})}
                                           target={section.targetBlank ? "_blank" : "_self"}
                                           className={styles.desktopImageWrapper}>
                                            {section.overlayText ?
                                                <div className={styles.overlayWrapper}>
                                                    <Typography
                                                        className={ClassNames('bg-dark', styles.overlayText, styles.hideMobile)}
                                                        size={1}
                                                        tone={'contrast'}
                                                        font={'bold'}
                                                    >{section.overlayText}</Typography>
                                                    {section.overlaySubText ?
                                                        <Typography
                                                            className={ClassNames('bg-dark p-color-secondary', styles.overlaySubText, styles.hideMobile)}
                                                            size={1}
                                                            tone={'contrast'}
                                                            font={'bold'}
                                                        >{section.overlaySubText}</Typography>
                                                        : null}
                                                </div>
                                                : null}
                                            <Image
                                                className={styles.desktopImage}
                                                src={section.desktopImage ? section.desktopImage : section.image}
                                                alt={section.imageAlt}
                                                title={section.imageAlt}
                                                sizes={`(max-width: 799px) 0px, 1280px`}
                                                style={imageStyleWithBorder(section?.border)}
                                                width={0}
                                                height={0}
                                                priority={placement === "top"}
                                            />
                                        </a>
                                        <a href={section.href} {...(section.seoFollow ? {} : {rel: 'nofollow'})}
                                           target={section.targetBlank ? "_blank" : "_self"}
                                           className={styles.mobileImageWrapper}>
                                            {section.overlayText ?
                                                <div className={styles.overlayWrapper}>
                                                    <Typography
                                                        className={ClassNames('bg-dark', styles.overlayText, styles.hideDesktop)}
                                                        tone={'contrast'}
                                                        font={'bold'}
                                                    >{section.overlayText}</Typography>
                                                    {section.overlaySubText ?
                                                        <Typography
                                                            className={ClassNames('bg-dark p-color-secondary', styles.overlaySubText, styles.hideDesktop)}
                                                            size={1}
                                                            tone={'contrast'}
                                                            font={'bold'}
                                                        >{section.overlaySubText}</Typography>
                                                        : null}
                                                </div>
                                                : null}
                                            <Image
                                                className={styles.mobileImage}
                                                src={section.mobileImage ? section.mobileImage : section.image}
                                                alt={section.imageAlt}
                                                title={section.imageAlt}
                                                sizes="(max-width: 799px) 828px"
                                                style={imageStyleWithBorder(section?.border)}
                                                width={0}
                                                height={0}
                                                priority={placement === "top"}
                                            />
                                        </a>
                                    </div>
                                )
                            })
                            return (
                                <div
                                    className={asset?.device !== "" || asset?.device != null ? deviceStyles(asset?.device) : styles.titleWrapper}>
                                    <Typography
                                        className={styles.title}
                                        size={1}
                                        tone={'contrast'}
                                        font={'bold'}
                                    >{row.title}</Typography>
                                    <div className={styles.rowWrapper}>
                                        {
                                            mobileScrollable ?
                                                <Carousel extendGutters>
                                                    {sections.map((sec, i) => {
                                                        return (
                                                            <Carousel.Item key={`mobile_scroll_section_${i}`}
                                                                           className={styles.mobileScrollCarouselItem}>
                                                                {sec}
                                                            </Carousel.Item>
                                                        )
                                                    })}
                                                </Carousel> :
                                                sections
                                        }
                                    </div>
                                </div>
                            )
                        }))
                })}
            </div>
        </>
    )
})

export default Campaign