import {Fragment, useContext, useEffect, useRef, useState} from 'react'
import ClassNames from 'classnames'
import {NavigationContext} from 'Clutch/Contexts/Navigation/NavigationContext'
import EnhancedProductCard from 'Clutch/Organisms/EnhancedProductCard'

import useWindow from 'Clutch/Hooks/useWindow'
import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag';
import useProductResultPage from '../../../hooks/useProductResultPage'
import Skeleton from 'Clutch/Atoms/Skeleton'
import styles from './verticalPage.module.scss'
import {useInView} from 'react-intersection-observer'
import Campaign from '../../campaign/campaign'

import {usePrpStore} from '/contexts/prpContext'
import {getNumProductsToBeLoaded} from '/Utilities/loadMoreUtilities'
import {useTimeToFirstProduct} from '../../../hooks/useTimeToFirstProduct'
import ArticleSearchCampaign from '../../campaign/ArticleSearchCampaign';
import {getLastPageVisited, setLastPageVisited} from '../../../Utilities/LocalStorage/previousPage'
import useVehicleSidebar from "../../../hooks/useVehicleSidebar";

const VerticalPageLayout = ({ productCardSection }) => {

    const skeleton = useProductResultPage(x => x.skeleton)
    const isSearchPage = useProductResultPage(x => x.isSearchPage)
    const searchProducts = useProductResultPage(x => x.searchProducts)
    const instrumentation = useProductResultPage(x => x.instrumentation)
    const campaign = useProductResultPage(x => x.campaign)
    const misoId = useProductResultPage(x => x.misoId)

    const navigationContext = useContext(NavigationContext);
    const productCard = useRef(null)
    const searchResponseProducts = searchProducts
    const [cardsPerRow, setCardsPerRow] = useState(Math.floor(productCardSection?.current?.clientWidth / productCard?.current?.clientWidth))

    const addToListEnabled = useFeatureFlag('PRP_Frontend_Add_To_List')
    const addToListExpanded = useFeatureFlag('Add_To_List_Badge_Expanded')
    const addToCartEnabled = useFeatureFlag('PRP_Add_To_Cart')
    const compareToolEnabled = useFeatureFlag('PRP_Compare_Tool')
    const LoadLazyProducts = usePrpStore(x => x.LoadLazyProducts)
    const numProductsLoading = usePrpStore(x => x.uiState.numProductsLoading)
    const spaLoading = usePrpStore(x => x.uiState.spaLoading)
    const loadingMoreProducts = usePrpStore(x => x.uiState.loadingMoreProducts)
    const SavePrpHistoryWithSetTimeout = usePrpStore(x => x.SavePrpHistoryWithSetTimeout)
    const OpenCompareToolModal = usePrpStore(x => x.OpenCompareToolModal)
    const pageContent = useProductResultPage(x => x.pageContent)

    const totalSearchResults = useProductResultPage(x => x.totalSearchResults)
    const currentPage = useProductResultPage(x => x.currentPage)
    const numProductsToLoad = getNumProductsToBeLoaded(totalSearchResults, currentPage)
    const selectedFacetOptions = useProductResultPage(x => x.selectedFacetOptions)
    const { vehicleName } = useVehicleSidebar(selectedFacetOptions);

    let campaignRows = campaign?.campaignPositions?.campaignGridPositions || []

    useWindow(() => {
        window.addEventListener("resize", () => {
            let currentNumCards = Math.floor(productCardSection?.current?.clientWidth / productCard?.current?.clientWidth)
            if (cardsPerRow != currentNumCards) {
                setCardsPerRow(currentNumCards)
            }
        })
    })

    useEffect(() => {
        setCardsPerRow(Math.floor(productCardSection?.current?.clientWidth / productCard?.current?.clientWidth))
    })

    useEffect(() => {
        if(typeof window !== 'undefined' && !skeleton){
            try{
                const prpLastPageVisited = getLastPageVisited()
                if(prpLastPageVisited?.url == window.location.href && prpLastPageVisited?.skuNumber){
                    document.getElementById(`Vertical_Product_Card_${prpLastPageVisited?.skuNumber}`).scrollIntoView({block: "center"});
                }
            }
            catch(err){
                console.error(err)
            }
        }
    }, [skeleton])

    const campaignIndices = campaignRows.map(row => cardsPerRow * row) || []
    let campaignCardIndices = []
    const embeddedAsset = campaign?.assets?.find(x => x.placement === "embedded")
    if (embeddedAsset?.cards)
        campaignCardIndices = campaign?.campaignPositions?.campaignCardPositions

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0,
        rootMargin: '400px 0px',
    })
    useEffect(() => { inView && LoadLazyProducts() }, [inView])

    useTimeToFirstProduct()

    let hasHitFirstLazyLoadedProduct = false;
    const LazyLoadProductsIntersectionObserver = () => {
        if (hasHitFirstLazyLoadedProduct || skeleton || spaLoading) {
            return false;
        }
        hasHitFirstLazyLoadedProduct = true;
        return (<span ref={ref}></span>)
    }

    const numSkelsToShow = (numProductsLoading > 0 && searchResponseProducts?.length > numProductsLoading) ? numProductsLoading : 0
    const shouldRibbonUseDollarsOff = useFeatureFlag("Ribbon_Text_Dollar_Vs_Percentage");

    return (
        <>
            {(searchResponseProducts || []).map((product, i) => {
                if (product.lazyLoad || spaLoading) {
                    if (numSkelsToShow > 0 && i >= numSkelsToShow) {
                        return false
                    }
                    return (<div className={ClassNames(styles.verticalProductCard, styles.productCardSkeleton)} key={`vertical-product-skeleton-${i}`}>{LazyLoadProductsIntersectionObserver()}<Skeleton height={500} fullWidth /></div>)
                }
                const productCardClickEvent = () => {
                    SavePrpHistoryWithSetTimeout()
                }
                const productDetails = product.skuBaseDetails

                let pdpProduct = {
                    displayTitle: productDetails?.displayTitle,
                    brand: productDetails?.brand,
                    sku: productDetails?.skuBaseNumber,
                    //image: productDetails?.imageLink ? (productDetails?.imageLink.includes("http") ? productDetails?.imageLink : `https:${productDetails?.imageLink}`) : null
                }

                if(!productDetails){
                    return false
                }

                return (
                    <Fragment key={`vertical_product_outer_${i}_${productDetails.skuBaseNumber}`}>
                        {campaignCardIndices?.some(campaignIndex => campaignIndex == i) ? <Campaign placement={"embedded"} ref={productCard} productCardIndex={i} /> : null}
                        {embeddedAsset?.cards ? null : campaignIndices.some(campaignIndex => campaignIndex == i) ? <Campaign placement={"embedded"} /> : null}
                        {!campaignRows?.length && i == cardsPerRow * 2 ? <ArticleSearchCampaign /> : false}
                        <article className={ClassNames(styles.verticalProductCard, 'productCard')} onClick={productCardClickEvent} ref={i == 0 ? productCard : null} id={`Vertical_Product_Card_${productDetails?.skuBaseNumber}`}>
                            <EnhancedProductCard.Vertical
                                imageLoadingProps={{ lazyBoundary: "250px", priority: i < 5 && !pageContent?.isMakerPage }}
                                product={productDetails}
                                fitmentTargetLabel={vehicleName}
                                page={'prp'}
                                search={isSearchPage} 
                                shop={!isSearchPage}
                                provider={instrumentation.metrics.searchProviderName}
                                misoId={misoId}
                                addToListEnabled={addToListEnabled}
                                addToListExpanded={addToListExpanded}
                                addToCartEnabled={addToCartEnabled}
                                compareToolEnabled={compareToolEnabled}
                                openCompareTool={() => OpenCompareToolModal(product.skuBaseNumber)}
                                onClick={(e) => {
                                    if (e.shiftKey || e.ctrlKey || e.metaKey) {
                                        return;
                                    }

                                    setLastPageVisited(productDetails?.skuBaseNumber)

                                    requestAnimationFrame(() =>
                                        setTimeout(() => navigationContext.setPdpSkeleton(true, pdpProduct))
                                    )
                                }
                                }
                                index={i}
                                shouldRibbonUseDollarsOff={shouldRibbonUseDollarsOff} />
                        </article>
                    </Fragment>
                )
            })}

            {loadingMoreProducts ? new Array(numProductsToLoad).fill({}).map((fakeProduct) => {
                return (
                    <div className={ClassNames(styles.verticalProductCard, styles.productCardSkeleton)}><Skeleton height={500} fullWidth /></div>
                )
            }) : false
            }
        </>
    )
}

export default VerticalPageLayout
