import { BreadcrumbJsonLd } from 'next-seo';

import useProductResultPage from '../../hooks/useProductResultPage'

const JsonLD = () => {

  const breadcrumbs = useProductResultPage(x => x.breadcrumb)
  const canonicalUrl = useProductResultPage(x => x.canonicalUrl)

  return (
    <>
      <BreadcrumbJsonLd
        keyOverride={'breadcrumb'}
        itemListElements={(breadcrumbs || []).map(crumb => {
          return {
            position: crumb.ordinality + 1,
            name: crumb.displayText,
            item: crumb.url ? `https://www.speedwaymotors.com${crumb.url}` : canonicalUrl,
          }
        })}
      />
    </>
  )
}

export default JsonLD
