import useProductResultPage from "../../hooks/useProductResultPage";
import { useEffect, useContext, memo } from "react";

import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { TrackingContext } from "Contexts/TrackingContext/TrackingContext";
import { NextSeo } from "next-seo";

const HeadContent = ({ pageType }) => {
  const userVin = useUserStore((x) => x.context.userVin);
  const userGuid = useUserStore((x) => x.context.userGuid);
  const logRocketInitialized = useUserStore((x) => x.context.logRocketInitialized);
  const { segmentReady } = useContext(TrackingContext);

  const skeleton = useProductResultPage(x => x.skeleton)
  const isFollow = useProductResultPage(x => x.isFollow)
  const isIndex = useProductResultPage(x => x.isIndex)
  const isNoResultPage = useProductResultPage(x => x.isNoResultPage)
  const isSearchPage = useProductResultPage(x => x.isSearchPage)
  const displayTitle = useProductResultPage(x => x.displayTitle)
  const instrumentation = useProductResultPage(x => x.instrumentation)
  const nextUrl = useProductResultPage(x => x.nextUrl)
  const pageTitle = useProductResultPage(x => x.pageTitle)
  const metaDescription = useProductResultPage(x => x.metaDescription)
  const canonicalUrl = useProductResultPage(x => x.canonicalUrl)
  const pageContent = useProductResultPage(x => x.pageContent)
  const representativeImageUrl = useProductResultPage(x => x.representativeImageUrl)
  const prevUrl = useProductResultPage(x => x.prevUrl)
  const misoCategories = useProductResultPage(x => x.misoCategories)
  const attribution = useProductResultPage(x => x.attribution)
  

  useEffect(() => {
    if (segmentReady && !skeleton) {
      if (isNoResultPage) {
        analytics.track("NRP Reached", {
          user_vin: `${userVin()}`,
          web_user_guid: `${userGuid}`,
          query: displayTitle,
          page_type: 'NRP'
        });
      }
    }
  }, [segmentReady, skeleton]);



  useEffect(() => {
    if (logRocketInitialized) {
      // Logging for LogRocket to pick things up as no other filter seems to be working
      console.log("PRP Next App Enabled");
    }
  }, [logRocketInitialized]);

  useEffect(() => {
    // LogRocket logging 
    if (attribution?.is_new_engine){
      console.log("New Engine Page")
    } else if (attribution?.is_engine){
      console.log("Old Engine Page")
    }
  }, [attribution])

  useEffect(() => {
    if (
      instrumentation?.bloomreachInitialPageLoad &&
      typeof window !== "undefined" &&
      window.fireMisoPageView
    ) {
      if (isSearchPage) {
        window.fireMisoPageView({
          ptype: "search",
          search_term: instrumentation.bloomreachInitialPageLoad.search_term,
          provider: instrumentation.metrics.searchProviderName
        });
      } else {
        window.fireMisoPageView({
          ptype: "category",
          cat: instrumentation.bloomreachInitialPageLoad.cat,
          provider:instrumentation.metrics.searchProviderName,
          miso_categories: misoCategories
        });
      }
    }
  }, [
    typeof window !== "undefined" ? window.fireMisoPageView : false,
    instrumentation?.bloomreachInitialPageLoad,
  ]);

  const linkTags = [
    {
      rel: "shortcut icon",
      href: "/favicon.ico",
    },
    {
      rel: "icon",
      href: "/favicon.ico",
      sizes: "152x152",
    },
  ];

  if (prevUrl)
    linkTags.push({
      rel: "prev",
      href: prevUrl,
    });

  if (nextUrl)
    linkTags.push({
      rel: "next",
      href: nextUrl,
    });

  const getAdditionalMetaTags = () => {
    let result = [
      {
        name: "viewport",
        content: "width=device-width,minimum-scale=1,initial-scale=1",
      },
      {
        name: "language",
        content: "english",
      },
      {
        name: "format-detection",
        content: "telephone=no",
      },
    ]
    if (representativeImageUrl) {
      result.push(
        {
          name: "thumbnail",
          content: representativeImageUrl,
        },
      )
    }
    return result;
  }


  return (
    <>

      <link rel={"preconnect"} href={"/graphql/discovery"}></link>

      <NextSeo
        title={pageTitle}
        description={metaDescription}
        canonical={canonicalUrl || null}
        noindex={!isIndex}
        nofollow={!isFollow}
        openGraph={{
          url: canonicalUrl || null,
          title: pageTitle,
          description: metaDescription,
          images: pageContent?.bannerImageUrl
            ? [
              {
                url:
                  pageContent?.bannerImageUrl || null,
                width: "1280",
                height: "300",
                alt: pageTitle,
              },
            ]
            : null,
        }}
        additionalMetaTags={getAdditionalMetaTags()}
        additionalLinkTags={linkTags}
      />
    </>
  );
};

export default memo(HeadContent);
