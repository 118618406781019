import { useContext } from "react";

import { useLazyFeatureFlag } from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag'
import useProductResultPage from "../../hooks/useProductResultPage";
import { useInView } from 'react-intersection-observer'
import RecommendationsCarousel from "Clutch/Containers/RecommendationsCarousel";
import Skeleton from 'Clutch/Atoms/Skeleton'
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import styles from './campaign.module.scss'
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";

const ArticleSearchCampaign = () => {
    return <ErrorBoundary><ArticleSearchCampaignInternal /></ErrorBoundary>
}

const ArticleSearchCampaignInternal = () => {
    const articleDetails = useProductResultPage(x => x.articleSearchResponse?.articleDetails)
    const isBot = useUserStore((x) => x.context.isBot);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0,
        rootMargin: '200px 0px',
    })

    if (!articleDetails?.length) {
        return false;
    }
    if (typeof (window) == "undefined" || isBot()) {
        return <ArticleSearchRack articles={articleDetails} />
    }
    if (!inView) {
        return <span ref={ref}></span>
    }
    return <ArticleSearchRack articles={articleDetails} />
}

export default ArticleSearchCampaign





const ArticleSearchRack = ({ articles }) => {
    const recBlock = {
        recommendations: articles.map(x => { return { articleId: x.id, articleDetails: x } }),
        useBorders: true,
        title: `Related Articles`,
        sectionType: "Article Search"
    }
    return <div className={styles.articles}><RecommendationsCarousel leftAlignCarousel showCarouselGradient extendGuttersForCarousel recBlock={recBlock} /></div>
}