import { useRef, useState, useEffect, useContext } from 'react'
import HorizontalPageLayout from './horizontalPage/horizontalPage'
import VerticalPageLayout from './verticalPage/verticalPage'
import Campaign from '../campaign/campaign'
import LoadMore from './pager/loadMore'
import { useUserStore } from "Clutch/Stores/UserStore/UserStore"

import useProductResultPage from '../../hooks/useProductResultPage'
import { useInView } from 'react-intersection-observer'
import styles from './products.module.scss'
import { usePrpStore } from '/contexts/prpContext'

import Feedback from 'Clutch/Molecules/Feedback'
import Skeleton from 'Clutch/Atoms/Skeleton'
import ArticleSearchCampaign from '../campaign/ArticleSearchCampaign'

const Products = ({ productListView, isMobile }) => {
    const skeleton = useProductResultPage(x => x.skeleton)
    const isSearchPage = useProductResultPage(x => x.isSearchPage)
    const selectedFacetOptions = useProductResultPage(x => x.selectedFacetOptions)
    const searchProducts = useProductResultPage(x => x.searchProducts)
    const instrumentation = useProductResultPage(x => x.instrumentation)
    const misoId = useProductResultPage(x => x.misoId)
    const nextUrl = useProductResultPage(x => x.nextUrl)
    
    const productCardSection = useRef(null)
    const LoadLazyProducts = usePrpStore(x => x.LoadLazyProducts)
    const hydrated = useUserStore((x) => x.context.hydrated);
    const isBot = useUserStore((x) => x.context.isBot);
    const [misoEventSent, setMisoEventSent] = useState(false)
    const searchResponseProducts = searchProducts

    //this is to handle lazy loading for back nav and page refreshes and such
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0,
        rootMargin: '1000px 0px 1000px 0px',
    })
    useEffect(() => {
        if (inView && hydrated && !isBot()) {
            LoadLazyProducts()
        }
    }, [inView, hydrated])

    useEffect(() => {
        if (!misoEventSent && typeof window !== 'undefined' && (searchResponseProducts || []).length) {
            if (window.fireSearchImpression !== undefined) {
                var productIds = searchResponseProducts.filter(product => product.skuBaseNumber).map(product => product.skuBaseNumber)
                if ((productIds || []).length) {
                    window.fireSearchImpression(productIds)
                    setMisoEventSent(true)
                }
            }
        }
    }, [searchResponseProducts, typeof window, typeof window !== 'undefined' ? window.fireSearchImpression : false])

    if (skeleton) {
        return (
            <div className={styles.productSectionSkeleton}>
                {[1, 2, 3, 4, 5].map(x => <Skeleton fullWidth height={300} />)}
            </div>
        )
    }

    let pageName = ''

    if (isSearchPage) {
        pageName = 'Search'
    }
    else {
        pageName = 'Shop'
    }


    const MFESpecificDataArray = selectedFacetOptions?.map(x => {
        let facet = `facet_${x.displayText}`
        facet = facet.replace(/[ ]/g, "_")
        facet = facet.replace(/[^a-z0-9_]/gi, '')
        facet = facet.toLowerCase()
        facet = facet.replace("searched_for", "query")

        const value = x.searchResponseFacetOptions?.map(y => y.displayValue).join(", ")
        let obj = {}
        obj[facet] = value
        return obj
    })

    const MFESpecificData = {}
    for (const element of MFESpecificDataArray) {
        for (const [key, value] of Object.entries(element)) {
            MFESpecificData[key] = value
        }
    }
    console.debug('MFESpecificData', MFESpecificData)



    return (
        <section aria-labelledby={'products'} className={productListView ? styles.section : styles.gridView} ref={productCardSection}>

            <h2 id={'products'} className={styles.h2}>Products</h2>
            {productListView ?
                <>
                    <HorizontalPageLayout isMobile={isMobile} />
                </>

                :
                <>
                    <VerticalPageLayout productCardSection={productCardSection} />
                </>
            }
            {!skeleton && (<>
                <LoadMore productListView={productListView} />
                {isMobile && <ArticleSearchCampaign />}
                <div className={styles.feedback}>
                    <Feedback name={pageName} searchProvider={instrumentation?.metrics?.searchProviderName} misoId={misoId} />
                </div>


                <span ref={ref}></span>

                <noscript>
                    {(searchProducts ?? []).filter(x => x.lazyLoad).map((x, i) => (<a href={x.lazyLoadData.url} key={`load-more-skeleton-${i}`}>{x.lazyLoadData.title}</a>))}
                    {nextUrl ?
                        <a href={nextUrl} >
                            Next Page
                        </a> : false
                    }

                </noscript></>)}


            <Campaign placement={"bottom"} />
        </section>
    )
}


export default Products
