import Image from 'next/image'
import {useContext} from 'react'
import ClassNames from 'classnames'

import styled from 'styled-components'
import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'

import LayerColorFinder from 'Clutch/Utilities/LayerColorFinder'

import styles from './facetOptionImageLink.module.scss'
import {usePrpStore} from '/contexts/prpContext'
const FacetOptionImageLink = ({ option, classNames, nofollow, border, setLoading, index, dataTestId }) => {

    const backgroundColor = LayerColorFinder(0)
    const borderColor = LayerColorFinder(1)

    const Navigate = usePrpStore(x => x.Navigate)
    return (
        <StyledOption className={ClassNames(styles.option, classNames)} backgroundColor={backgroundColor} borderColor={borderColor} border={border} data-testid={dataTestId}>
            <TextLink href={option.facetOptionLink.url} className={styles.link} nofollow={nofollow} onClick={(e) => Navigate(e, option.facetOptionLink.url, setLoading)}>
                {option.thumbnailImageUrl ?
                    <div className={styles.optionImage}>
                        <Image
                            src={option.thumbnailImageUrl}
                            alt={option.displayValue}
                            width={'88'}
                            height={'88'} 
                            priority={index < 6}/>
                    </div>
                    : null
                }
                <Typography size={0.875} tone={'contrast'} className={styles.option_name}>
                    {option.displayValue}
                </Typography>
            </TextLink>
        </StyledOption>
    )
}

const StyledOption = styled.div`
    background-color: ${(props) => props.backgroundColor};
    border-color: ${(props) => props.borderColor};
    border: ${(props) => props.border ?? 'none'};
    &:hover {
        background-color: ${(props) => props.borderColor};
    }
`;

export default FacetOptionImageLink