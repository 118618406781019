import { useState, useEffect, useRef } from 'react'
import useProductResultPage from "../../hooks/useProductResultPage";
import Modal from 'Clutch/Components/Modal/ModalWithPortal'
import styles from './searchProviderBoostingData.module.scss'
import Typography from 'Clutch/Atoms/Typography/Typography'
import Button from 'Clutch/Molecules/Button/Button'
import Popover from 'Clutch/Components/Popover'
import Image from 'next/image'
import Card from "Clutch/Atoms/Card";
import Svg from 'Clutch/Atoms/Svg'
import classNames from 'classnames';

const SearchProviderBoostingData = () => {
    const instrumentation = useProductResultPage(x => x.instrumentation);
    const campaign = useProductResultPage(x => x.campaign);
    try {
        const [showRules, setShowRules] = useState(false)
        const [copied, setCopied] = useState(false)
        const [showingSku, setShowingSku] = useState(null)
        const modal = useRef(null)

        useEffect(() => {
            if (showRules) modal?.current?.open();
            if (!showRules) modal?.current?.close();
        }, [showRules]);

        if (!instrumentation?.metrics?.boostingTags && !instrumentation?.metrics?.boostingCampaigns && !instrumentation?.metrics?.productCampaigns && !instrumentation?.metrics?.searchProviderRequestJson) return false

        const assets = instrumentation?.metrics?.boostingCampaigns
        const products = instrumentation?.metrics?.productCampaigns
        const boostingTags = instrumentation?.metrics?.boostingTags
        const searchRequestJson = instrumentation?.metrics?.searchProviderRequestJson
        
        return (
            <>
                <Button className={styles.boostingRulesButton} brand={'primary'} size={'small'} onClick={() => setShowRules(!showRules)} segmentEvent={{ event: 'searchProviderBoostingRules' }}>
                    <Typography size={1}>Boosting Rules</Typography>
                </Button>
                <Modal
                    id={"searchProviderBoostingDataModal"}
                    outsideClick
                    ref={modal}
                    customOnClose={() => setShowRules(false)}
                >
                    <div className={styles.searchProviderBoostingDataModal}>
                        <div className={styles.section}>
                            {boostingTags?.length ?
                                <div>
                                    <Typography font={'bold'} as={'h2'} brand={'primary'} style={{ marginBottom: '1rem' }}>Boosting Tags</Typography>
                                    <div className={styles.listSection}>
                                        {boostingTags.map((x, i) => {
                                            return <Card noPadding noMargin><Typography className={styles.smallMargin}>{x}</Typography></Card>
                                        })}
                                    </div>
                                </div> :
                                <Typography>No Boosting Tags For This Page</Typography>}
                        </div>
                        <div className={styles.section}>
                            {products?.length ?
                                <div>
                                    <Typography font={'bold'} as={'h2'} brand={'primary'} style={{ marginBottom: '1rem' }}>Product Campaigns</Typography>
                                    {products.map((x, i) => {
                                        return (
                                            <Card noMargin className={styles.boostingCard} key={`productBoosting-${i}`}>
                                                <Typography font={'bold'} as={'h3'} style={{ marginBottom: '0.5rem' }}>Boost Name</Typography>
                                                <Typography style={{ marginBottom: '1rem' }}>{x.name}</Typography>
                                                <Typography font={'bold'} as={'h3'} style={{ marginBottom: '0.5rem' }}>Applied Boosting Configuration</Typography>
                                                <Typography style={{ marginBottom: '1rem' }}>{x.appliedBoosting}</Typography>
                                                <Typography font={'bold'} as={'h3'} style={{ marginBottom: '0.5rem' }}>Boosted Products</Typography>
                                                <div className={styles.listSection}>
                                                    {x.boostedSkuDetails.map((sku, j) => {
                                                        return (
                                                            <Popover
                                                                content={
                                                                    <Card href={sku?.productPageUrl} style={{ width: '300px' }}>
                                                                        <Typography font={'bold'}>{sku?.displayTitle}</Typography>
                                                                        <Image src={`https:${sku?.imageLink}`} width={200} height={200} />
                                                                    </Card>
                                                                }
                                                                onToggleMenu={(x) => setShowingSku(x == true ? `${i}-${j}` : null)}
                                                                isOpen={showingSku === `${i}-${j}`}
                                                            >
                                                                <Card noPadding noMargin className={styles.sku}><Typography className={styles.smallMargin}>{sku.skuBaseNumber}</Typography></Card>
                                                            </Popover>
                                                        )
                                                    })}
                                                </div>
                                            </Card>)
                                    })}
                                </div>
                                : <Typography>No Product Boosting Applied</Typography>}
                        </div>
                        <div className={styles.section}>
                            {assets?.length ?
                                <div>
                                    <Typography font={'bold'} as={'h2'} brand={'primary'} style={{ marginBottom: '1rem' }}>Asset Campaigns</Typography>
                                    {assets.map((x, i) => {
                                        return (
                                            <Card noMargin className={styles.boostingCard} key={`asset-${i}`}>
                                                <Typography font={'bold'} as={'h3'} style={{ marginBottom: '0.5rem' }}>Campaign Name</Typography>
                                                {(campaign?.assets || []).map(x => x.assetName).map((name) => (
                                                    <Typography style={{ marginBottom: '1rem' }}>{name}</Typography>
                                                ))}
                                                <Typography font={'bold'} as={'h3'} style={{ marginBottom: '0.5rem' }}>Raw Campaign</Typography>
                                                <Typography style={{ marginBottom: '1rem' }}>{x.campaignJson}</Typography>
                                                {x.matchedQueries?.length ?
                                                    <>
                                                        <Typography font={'bold'} as={'h3'} style={{ marginBottom: '0.5rem' }}>Matched Queries</Typography>
                                                        <div className={styles.listSection}>
                                                            {x.matchedQueries.map((query, i) => {
                                                                return <Card noPadding noMargin><Typography className={styles.smallMargin}>{query}</Typography></Card>
                                                            })}
                                                        </div>
                                                    </> : false}
                                                {x.matchedTags?.length ?
                                                    <>
                                                        <Typography font={'bold'} as={'h3'} style={{ marginBottom: '0.5rem' }}>Matched Tags</Typography>
                                                        <div className={styles.listSection}>
                                                            {x.matchedTags?.map((tag, i) => {
                                                                return <Card noPadding noMargin><Typography className={styles.smallMargin}>{tag}</Typography></Card>
                                                            })}
                                                        </div>
                                                    </> : false}
                                            </Card>)
                                    })}
                                </div>
                                : <Typography>No Assets Applied</Typography>}
                        </div>
                        {searchRequestJson ? 
                        <>
                            <Typography font={'bold'} as={'h2'} brand={'primary'}>Search Provider Request</Typography>
                            <Card>
                                <span style={{float: 'right'}}><Svg icon={copied ? 'check' : 'copy'} size={1} onClick={() => {navigator.clipboard.writeText(searchRequestJson); setCopied(true)}}/></span>
                                <pre>{searchRequestJson}</pre>
                            </Card>
                        </> : false}
                    </div>
                </Modal>
            </>
        );
    } catch (e) {
        console.error(e)
        return false;
    }
}

export default SearchProviderBoostingData