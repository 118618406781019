import React, {useState} from "react";
import classNames from "classnames";

import Head from "next/head";
import Image from "next/legacy/image";

import MakerEnhance from "maker-enhance";

import {usePrpStore} from '../../contexts/prpContext'
import useProductResultPage from "../../hooks/useProductResultPage";

import TextLink from "Clutch/Atoms/TextLink";
import Typography from 'Clutch/Atoms/Typography'
import Gutters from 'Clutch/Atoms/Gutters'
import Panel from 'Clutch/Atoms/Panel'
import CoveringSpinner from 'Clutch/Atoms/CoveringSpinner'

import Breadcrumb from 'Clutch/Molecules/Breadcrumb/Breadcrumb'

import buildBreadCrumb from '../../Utilities/buildBreadCrumb';
import GetFacetToRemove from "../../Utilities/suggestedFacetsToRemove";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";

import styles from './aplusHeaderContent.module.scss'
import Svg from "Clutch/Atoms/Svg";
import {
    createClickedSegmentEvent
} from "@speedwaymotors/clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";


const AplusHeaderContent = ({overrideData}) => {
    if (overrideData) {
        throw "override data was passed and is no longer supported"
    }

    const pageContent = useProductResultPage(x => x.pageContent)

    if (!pageContent) {
        return false;
    }

    if (
        !pageContent.isMakerPage &&
        !pageContent.bannerImageUrl
    ) {
        return false;
    }

    if (pageContent.isMakerPage) {
        return <MemoMakerContent
            makerContentMobileHeight={pageContent.makerContentMobileHeight}
            makerContentTabletHeight={pageContent.makerContentTabletHeight}
            makerContentDesktopHeight={pageContent.makerContentDesktopHeight}
        />
    }

    return <SmiNetContent/>
};

const MakerContent = ({makerContentMobileHeight, makerContentTabletHeight, makerContentDesktopHeight}) => {
    const heightStyles = {
        "--m": makerContentMobileHeight,
        "--t": makerContentTabletHeight,
        "--d": makerContentDesktopHeight
    }

    return (
        <ErrorBoundary>
            <Head>
                <link
                    rel="preload"
                    href="https://app.maker.co/enhance/speedway.js"
                    as="script"
                />
            </Head>
            <div className={styles.spacer} style={heightStyles}>
                <MakerEnhance
                    user={"speedway"}
                    index={"header"}
                    //this doesn't work because maker content is NOT a fixed height and this expects a fixed height.
                    //you will still see CLS at any viewport width that isn't exactly the provided breakpoint
                    // loadingHeight={
                    //   pageContent.makerContentDesktopHeight
                    // }
                />
            </div>

        </ErrorBoundary>
    );
}

const MemoMakerContent = React.memo(MakerContent)

const SmiNetContent = () => {
    const displayTitle = useProductResultPage(x => x.displayTitle)
    const isNoResultPage = useProductResultPage(x => x.isNoResultPage)
    const isSearchPage = useProductResultPage(x => x.isSearchPage)
    const totalSearchResults = useProductResultPage(x => x.totalSearchResults)
    const selectedFacetOptions = useProductResultPage(x => x.selectedFacetOptions)
    const searchSpelling = useProductResultPage(x => x.searchSpelling)
    const breadcrumb = useProductResultPage(x => x.breadcrumb)
    const pageContent = useProductResultPage(x => x.pageContent)
    const isPartialMatch = useProductResultPage(x => x.isPartialMatch)

    const shopPageWithProducts = !isSearchPage && !isNoResultPage
    const titleComponent = <HeaderTitle isForMobile={true} breadcrumbPlaceholder={shopPageWithProducts && !breadcrumb}/>
    const SEARCHTERMPLACEHOLDER = `Searching...`

    return (
        <ErrorBoundary>
            {!shopPageWithProducts &&
                <Panel inverseTheme className={classNames(styles.hideDesktop, styles.nonShopBannerContain)}>
                    <Image
                        id={'prp_banner_img_mobile'}
                        src={pageContent.bannerImageUrl}
                        layout={"fill"}
                        objectFit={'cover'}
                        priority
                        alt={''}
                    />
                    <div className={styles.bannerGradient}></div>
                    <Gutters className={styles.bannerContent}>
                        <div data-testid={'page_title_mobile'}>
                            {isSearchPage && !isNoResultPage && !isPartialMatch && (
                                displayTitle !== SEARCHTERMPLACEHOLDER ? (
                                    <Typography className={styles.resultsText} size={0.875} as={'h1'}>
                                        {totalSearchResults} results for: {titleComponent}
                                    </Typography>
                                ) : (
                                    <Typography className={styles.resultsText} size={0.875} as={'h1'}>
                                        {titleComponent}
                                    </Typography>
                                )
                            )}
                            {isNoResultPage && (
                                <>
                                    <div className={styles.nrpText}>
                                        <Svg intent={"negative"} tone={"contrast"} icon="unavailable" size={0.75}/>
                                        <Typography className={styles.resultsText} size={0.875}>
                                            No results found for:
                                        </Typography>
                                    </div>
                                    {titleComponent}
                                </>
                            )}
                            {isPartialMatch && !isNoResultPage && isSearchPage && (
                                <>
                                    <div className={styles.nrpText}>
                                        <Svg intent={"negative"} tone={"contrast"} icon="unavailable" size={0.75}/>
                                        <Typography font={'bold'} className={styles.resultsText} size={0.875} as={'h1'}>
                                            No exact match found for: {titleComponent}
                                        </Typography>
                                    </div>
                                </>
                            )}
                            {!isSearchPage && !isNoResultPage && !isPartialMatch &&
                                <Breadcrumb breadcrumb={buildBreadCrumb(breadcrumb)}/>}
                            <Subtitle isForMobile selectedFacetOptions={selectedFacetOptions}
                                      isSearchPage={isSearchPage}
                                      searchSpelling={searchSpelling} isNoResultPage={isNoResultPage}
                                      isPartialMatch={isPartialMatch}/>
                        </div>

                    </Gutters>
                </Panel>

            }
            <Panel inverseTheme
                   className={classNames(!isSearchPage ? styles.bannerContain : styles.nonShopBannerContain, shopPageWithProducts ? '' : styles.hideMobile)}>
                <Image
                    id={'prp_banner_img_desktop'}
                    src={pageContent.bannerImageUrl}
                    width={"1800"}
                    height={"442"}
                    layout={"fill"}
                    objectFit={'cover'}
                    priority
                    alt={''}
                />
                <div className={styles.bannerGradient}></div>
                <Gutters className={styles.bannerContent}>
                    <div data-testid={'page_title_desktop'}>
                        {isSearchPage && !isNoResultPage && !isPartialMatch && displayTitle !== SEARCHTERMPLACEHOLDER &&
                            <Typography font={'bold'} size={1.125}>Showing results for:</Typography>}
                        {isPartialMatch && isSearchPage && !isNoResultPage && (
                            <div className={styles.nrpText}>
                                <Svg intent={"negative"} tone={"contrast"} icon="unavailable" size={0.75}/>
                                <Typography font={'bold'} className={styles.resultsText} size={0.875} as={'h1'}>No exact
                                    match found for:</Typography>
                            </div>)}
                        {isNoResultPage && (
                            <>
                                <div className={styles.nrpText}>
                                    <Svg intent={"negative"} tone={"contrast"} icon="unavailable" size={0.75}/>
                                    <Typography font={'bold'} size={1.125}>No results found for:</Typography>
                                </div>
                            </>)}
                        {shopPageWithProducts &&
                            <Breadcrumb breadcrumb={buildBreadCrumb(breadcrumb)}/>}
                        <div className={styles.bannerTitle}>
                            <div className={styles.bannerTextStack}>
                                <HeaderTitle breadcrumbPlaceholder={shopPageWithProducts && !breadcrumb}/>
                                <Subtitle isForMobile={false} selectedFacetOptions={selectedFacetOptions}
                                          isSearchPage={isSearchPage} searchSpelling={searchSpelling}
                                          isNoResultPage={isNoResultPage} isPartialMatch={isPartialMatch}/>
                            </div>
                            {pageContent.brandImageUrl ?
                                <>
                                    <div className={styles.bannerBrandImage}>
                                        <Image
                                            src={pageContent.brandImageUrl}
                                            alt={`Brand logo for ${displayTitle}`}
                                            width="96"
                                            //brand images seem to always be oblong, regardless of actual brand logo dimensions
                                            height="50%"
                                            layout="responsive"
                                            objectFit="contain"
                                            priority
                                        />
                                    </div>
                                </> : null}
                        </div>
                    </div>
                </Gutters>
            </Panel>
        </ErrorBoundary>
    );
}

const HeaderTitle = ({breadcrumbPlaceholder, isForMobile = false}) => {

    const displayTitle = useProductResultPage(x => x.displayTitle)
    const isSearchPage = useProductResultPage(x => x.isSearchPage)

    const SEARCHTERMPLACEHOLDER = `Searching...`

    const openSaytModal = () => {
        if (isSearchPage) {
            const event = new CustomEvent('sayt_modal_opened', {
                detail: {
                    isEditing: true
                }
            });
            window.dispatchEvent(event)
        }
    };

    if (!isSearchPage) {
        return <Typography font={'bold'} tone={'contrast'} size={isForMobile ? 1.25 : 2} id={`title`} as={'h1'}
                           className={breadcrumbPlaceholder ? styles.breadcrumbPlaceholder : undefined}>
            {displayTitle}
        </Typography>
    }

    return <>
        <TextLink
            onClick={openSaytModal}
            segmentEvent={createClickedSegmentEvent("EditSearchQuery")}
            font={'bold'}
            tone={'contrast'}
            id={`title-${isForMobile ? 'mobile' : 'desktop'}`}
            size={isForMobile ? 1.25 : 2}
            as={isForMobile ? 'span' : 'h1'}
            className={breadcrumbPlaceholder ? styles.breadcrumbPlaceholder : undefined}
        >
            <div className={styles.headerContainer}>
                <div data-testid="title-text">{displayTitle}</div>
                {displayTitle !== SEARCHTERMPLACEHOLDER && (
                    <span className={styles.iconWrapper}>
                        <Svg
                            data-testid={'display_title_edit_svg'}
                            icon="edit"
                            size={isForMobile ? 0.75 : 1}
                            tone={"contrast"}
                        />
                    </span>
                )}
            </div>
        </TextLink>
    </>
}

const Subtitle = ({
                      isNoResultPage,
                      searchSpelling,
                      selectedFacetOptions,
                      isSearchPage,
                      isForMobile,
                      isPartialMatch
                  }) => {
    const Navigate = usePrpStore(x => x.Navigate)
    const [waitingRemoveFacet, setWaitingRemoveFacet] = useState(false)
    const searchResponseFacets = useProductResultPage(x => x.searchResponseFacets)
    const totalSearchResultsCount = useProductResultPage(x => x.totalSearchResultsCount)

    if (searchSpelling?.explaination) {
        return <Typography data-testid={"search_spelling_explaination"}
                           size={0.875}>{searchSpelling?.explaination}</Typography>
    }

    if (isNoResultPage) {

        const facetToRemove = GetFacetToRemove({
            selectedFacetOptions,
            isSearchPage,
        });

        if (facetToRemove) {
            const linkText = `removing the ${facetToRemove.name} filter`
            const removeFiltersLink = (<CoveringSpinner isLoading={waitingRemoveFacet}>
                <TextLink font={'bold'} onClick={(e) => Navigate(e, facetToRemove.url, setWaitingRemoveFacet)}
                          underline
                          href={facetToRemove.url}>{linkText}</TextLink>
            </CoveringSpinner>)

            if (isSearchPage) {
                return (<Typography size={0.875}>
                    Try more general search terms or {removeFiltersLink}
                </Typography>)
            } else {
                return (<Typography size={0.875}>
                    Try {removeFiltersLink}

                </Typography>)
            }
        }
        const shopAllLink = <TextLink font={'bold'} tone={'contrast'} underline href={`/shop/all`}>shop all
            parts</TextLink>

        return (<Typography size={0.875}>
            Try more general search terms or {shopAllLink}
        </Typography>)
    }

    if (!isNoResultPage && isPartialMatch && isSearchPage) {
        return (<Typography size={0.875}>
            Results found are partial matches and might not be exactly what you're looking for.
        </Typography>)
    }

    const showCategoryHintText = searchResponseFacets?.some(x => x.isForTopCategories || x.isForSearchFacetResultPage) && totalSearchResultsCount > 100 && isSearchPage

    if (!isForMobile && showCategoryHintText) {
        return <Typography size={0.875}>Narrow your search using the categories below</Typography>;
    }


}

export default AplusHeaderContent;
