import { useContext, useState, useRef, useLayoutEffect } from 'react'
import ClassNames from 'classnames'

import styles from './criticalAttributes.module.scss'

import useProductResultPage from "../../hooks/useProductResultPage";
import { usePrpStore } from '../../contexts/prpContext';

import Typography from 'Clutch/Atoms/Typography'
import TextLink from 'Clutch/Atoms/TextLink'
import Panel from 'Clutch/Atoms/Panel'
import CoveringSpinner from "Clutch/Atoms/CoveringSpinner";
import Button from 'Clutch/Molecules/Button'

import useSegment from "Clutch/Hooks/useSegment"
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import useLogRocket from 'Hooks/useLogRocket'

const CriticalAttributes = () => {
    const searchResponseFacets = useProductResultPage(x => x.searchResponseFacets);
    const totalSearchResults = useProductResultPage(x => x.totalSearchResults);
    const categoryFacet = searchResponseFacets.find(facet => facet.isForTopCategories);
    const Navigate = usePrpStore(x => x.Navigate);
    const [isLoading, setIsLoading] = useState(false)
    const [isExpanded, setIsExpanded] = useState(false)
    const topCatAreaRef = useRef(null)
    const [expandable, setExpandable] = useState(false)
    const { sendCustomSegmentTrackEvent } = useSegment()
    const { logRocketTrack } = useLogRocket();

    if (!categoryFacet || !categoryFacet.searchResponseFacetOptions?.length) return false

    const options = categoryFacet.searchResponseFacetOptions

    const optionsWithAll = [{id: options[0].id, skip: true, resultCount: totalSearchResults, displayValue: 'All' }, ...options]

    const numCols = optionsWithAll.length < 4 ? options.length : 4

    useLayoutEffect(() => {
        const canBeExpanded = topCatAreaRef.current?.scrollHeight - topCatAreaRef.current?.clientHeight > 5
        setExpandable(canBeExpanded);
    }, [categoryFacet]);

    const handleOptionClick = (e, segmentEvent, option) => {
        sendCustomSegmentTrackEvent(segmentEvent);
        logRocketTrack(`Critical_Attribute_Clicked${option.skip ? '_all' : ''}`)
        Navigate(e, option.skip ? `${window?.location?.pathname}${window?.location?.search}` : option.facetOptionLink.url, setIsLoading, option.skip ? option.id : null);
    }

    return (
        <CoveringSpinner className={styles.coveringSpinnerWrapper} isLoading={isLoading} fillWidth>
            <Panel className={ClassNames(styles.container, expandable ? styles.extraSpaceForButton : false)} layer={0}>
                <div className={styles.facet}>
                    <Typography size={1.25} font={'bold'} className={styles.title}>Select {categoryFacet.facetName}</Typography>
                    <div className={ClassNames(styles.cardContainer, !isExpanded ? styles.collapsed : false)} style={{ '--numCols': numCols }} ref={topCatAreaRef}>
                        {optionsWithAll.map((x, i) => {
                            const event = createClickedSegmentEvent(`CriticalAttribute${i == 0 ? '_Skip' : ''}`)
                            return (
                                <TextLink data-testid={`critical_attribute_${x.displayValue}`} key={`critical_attribute_${i}`} onClick={e => handleOptionClick(e, event, x)} as={'div'} className={styles.option}>
                                    <div className={styles.optionValue}>{x.displayValue}</div>
                                    <Typography size={0.75} tone={'superfluous'}>{x.resultCount}</Typography>
                                </TextLink>
                            )
                        })}
                    </div>
                    {expandable ?
                        <div className={styles.moreWrapper}>
                            <Button className={styles.more} onClick={() => { setIsExpanded(!isExpanded) }} segmentEvent={createClickedSegmentEvent('ExpandCriticalAttributeTopCategories')} size={'small'} intent={'subdued'}>See {isExpanded ? 'Less' : 'All'}</Button>
                        </div> : false}
                </div>
            </Panel>
        </CoveringSpinner>
    )
}


export default CriticalAttributes