import SortTypeSelect from './sortTypeSelect/sortTypeSelect'
import SelectedFacets from './selectedFacets/selectedFacets'
import Divider from 'Clutch/Atoms/Divider'
import Typography from 'Clutch/Atoms/Typography'
import { Default, Selected } from 'Clutch/Molecules/Button/Variants/OptionButtons'
import Svg from 'Clutch/Atoms/Svg'

import ClassNames from 'classnames'
import { StorageAccessor } from 'Clutch/Utilities/LocalStorageUtility'

import styles from './options.module.scss'

import useProductResultPage from '../../hooks/useProductResultPage'
import { PrpListSelectedKey } from '../../constants/localStorageKeys'


import AppName from 'AppName'

const Options = ({ setProductListView, isProductListView }) => {

  const skeleton = useProductResultPage(x => x.skeleton)
  const isNoResultPage = useProductResultPage(x => x.isNoResultPage)
  const totalSearchResults = useProductResultPage(x => x.totalSearchResults)

  let ListViewButton = isProductListView ? Selected : Default
  let GridViewButton = isProductListView ? Default : Selected

  const handleLayoutToggle = (isList, type) => {
    StorageAccessor.localStorage.setObj(PrpListSelectedKey, isList ? true : false, 3600)

    setProductListView(isList)
  }

  if (skeleton) {
    return false
  }

  if (isNoResultPage) {
    return <>
      <SelectedFacets location="noResultPage" />
      <Divider className={ClassNames(styles.divider, styles.hideMobile)} />
    </>

  }

  return (
    <>
      <div className={styles.options}>
        <div className={styles.productCardLayoutToggles}>
          <ListViewButton data-testid={"listViewButton"} title={!isProductListView ? `Switch to list view` : `List view (current)`} key={`list_view_button_${isProductListView}`} classNames={styles.layoutToggleButton} svg={<Svg icon={'list-view'} size={1.25} />} brand={'primary'} size={'small'} onClick={() => handleLayoutToggle(true, 'List')} />
          <GridViewButton data-testid={"gridViewButton"} title={isProductListView ? `Switch to grid view` : `Grid view (current)`} key={`grid_view_button_${isProductListView}`} classNames={styles.layoutToggleButton} svg={<Svg icon={'grid-view'} size={1.25} />} brand={'primary'} size={'small'} onClick={() => handleLayoutToggle(false, 'Grid')} />
        </div>
        <Typography size={0.875} className={styles.count}>
          {skeleton ? <>Loading products...</> : <>{totalSearchResults + ` ${totalSearchResults == 1 ? 'product' : 'products'}`}</>}

        </Typography>

        <div className={styles.sortBy}>
          <SortTypeSelect />
        </div>

      </div>
      <Divider className={ClassNames(styles.divider, styles.hideMobile)} />
      <SelectedFacets wrap />
    </>
  )
}


export default Options
