import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import { usePrpStore } from '../contexts/prpContext'

import useProductResultPage from './useProductResultPage'
import logrocket from 'logrocket'
import { useEffect } from 'react'

const useTimeToFirstProduct = () => {

    const skeleton = useProductResultPage(x => x.skeleton)
    const productsLoaded = usePrpStore(x => x.uiState.productsLoaded)
    const SetProductsLoaded = usePrpStore(x => x.SetProductsLoaded)

    const isBot = useUserStore((x) => x.context.isBot);
    const logRocketInitialized = useUserStore((x) => x.context.logRocketInitialized);

    useEffect(() => {
        try {
            if(typeof window !== 'undefined' && window.pageStart && !isBot()){
                if(!skeleton && !productsLoaded){
                    window.timeToFirstProduct = new Date().getTime() - window.pageStart
                    SetProductsLoaded(true)
                    if(logRocketInitialized){
                        logrocket.track('TimeToFirstProduct', {
                            time: window.timeToFirstProduct
                        })
                    }
                }
                else if(logRocketInitialized && productsLoaded){
                    logrocket.track('TimeToFirstProduct', {
                        time: window.timeToFirstProduct
                    })
                }
            }
        }
        catch (err) {
            console.error(err)
        }
    }, [skeleton, logRocketInitialized])
}

export { useTimeToFirstProduct }