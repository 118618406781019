import React, {useState} from 'react';
import Area from 'Clutch/Molecules/Area';
import Typography from 'Clutch/Atoms/Typography';
import Popover from 'Clutch/Components/Popover';
import Card from 'Clutch/Atoms/Card';
import styles from './productOrdering.module.scss';

const ProductOrdering = ({misoOrderByConfig}) => {

    const [isOpen, setIsOpen] = useState(false);
    const hasOrderingRules = misoOrderByConfig && Object.values(misoOrderByConfig).some(
        value => value && value.misoOrderByRules && value.misoOrderByRules.length > 0
    );
    
    const handleMouseEnter = () => {
        if (hasOrderingRules) {
            setIsOpen(true);
        }
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    const renderOrderingRules = (configKey, rules) => {
        if (!rules || rules.length === 0) return null;
        return (
            <Area key={configKey} intent="positive" className={styles.ruleGroup}>
                <Typography size={1} className={styles.ruleGroupTitle}><strong>{configKey}</strong></Typography>
                <ul className={styles.ruleList}>
                    {rules.map((rule, index) => (
                        <li key={index} className={styles.ruleItem}>
                            <div className={styles.ruleInfo}>
                                <Typography size={0.875} className={styles.ruleName}>
                                    {rule.field}
                                </Typography>
                                <Typography size={0.75} className={styles.ruleOrder}>
                                    Order: {rule.order}
                                </Typography>
                            </div>
                        </li>
                    ))}
                </ul>
            </Area>
        );
    };

    const treatmentText = misoOrderByConfig?.treatment
        ? `Ordering Treatment: ${misoOrderByConfig.treatment}`
        : 'No Ordering Treatment';

    const areaContent = (
        <Area
            className={styles.rule}
            title={treatmentText}
            intent={hasOrderingRules ? 'positive' : undefined}
        >
            <Typography size={0.75}>
                {treatmentText}
            </Typography>
        </Area>
    );

    return (
        <Card noMargin noPadding className={styles.card}>
            <div
                className={styles.rulesWrapper}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {hasOrderingRules ? (
                    <Popover
                        content={
                            <div className={styles.popoverContent}>
                                {misoOrderByConfig && Object.entries(misoOrderByConfig).map(([key, value]) => {
                                    if (value && value.misoOrderByRules) {
                                        return renderOrderingRules(key, value.misoOrderByRules);
                                    }
                                    return null;
                                })}
                            </div>
                        }
                        isOpen={isOpen}
                    >
                        {areaContent}
                    </Popover>
                ) : (
                    areaContent
                )}
            </div>
        </Card>
    );
};

export default ProductOrdering;