import {useContext, useEffect, useState} from 'react'
import useProductResultPage from '../../hooks/useProductResultPage'
import AdditionalLinks from './additionalLinks';
import { usePrpStore } from '/contexts/prpContext'
import { useInView } from 'react-intersection-observer'

const AdditionalLinksGroup = ({ propName }) => {
    const fromState = useProductResultPage(x => x[propName])
    const LazyHydrateLateralFacets = usePrpStore(x => x.LazyHydrateLateralFacets)
    const [hasFetched, setHasFetched] = useState(false)

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0,
        rootMargin: '500px 0px',
    })

    useEffect(() => {
        if(fromState && !fromState.some(x => x.isLateralFacet) && inView && !hasFetched){
            const getLateralFacet = async () => {
                await LazyHydrateLateralFacets()
                setHasFetched(true)
            }
            getLateralFacet()
        }
    }, [inView])

    if (!fromState) {
        return false;
    }

    return (<>
        <div ref={ref}></div>
        {fromState.map((group, i) => (
            <AdditionalLinks links={group.links} title={group.title} key={`additional_link_group_${i}`} />
        ))}
    </>)

}

export default AdditionalLinksGroup;