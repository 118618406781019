import { useContext, useState } from "react";

import Typography from "Clutch/Atoms/Typography";
import styles from "./stickyHeader.module.scss";
import Svg from "Clutch/Atoms/Svg";
import useProductResultPage from "../../hooks/useProductResultPage";
import useVehicleSidebar from "../../hooks/useVehicleSidebar";
import ClassNames from "classnames";
import StickyHeaderSkeleton from "./stickyHeaderSkeleton";
import Button from "Clutch/Molecules/Button";

import { usePrpStore } from "/contexts/prpContext";
import { showAddToGarage, addToGarage, showUpdateCustomerProject, updateCustomerProject } from "../../Utilities/garageUtilities";
import { NotificationContext } from "Clutch/Contexts/NotificationContext/NotificationContext";
import { createClickedSegmentEvent } from "Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents";
import { useVehicleStore } from "Clutch/Stores/VehicleStore";
import { useFeatureFlag } from "Clutch/Hooks/useFeatureFlag/useFeatureFlag";
import { NavigationContext } from "Clutch/Contexts/Navigation/NavigationContext";
import { useUserSpecificProductDataStore } from "Clutch/Contexts/UserSpecificProductDataStore/UserSpecificProductDataStore";

const StickyHeaderContent = ({ classNames }) => {
  const [loading, setLoading] = useState(false);

  const customerProjects = useVehicleStore((x) => x.context.customerProjects);
  const selectedFacets = useProductResultPage((x) => x.selectedFacetOptions);
  const skeleton = useProductResultPage((x) => x.skeleton);
  const isNoResultPage = useProductResultPage((x) => x.isNoResultPage);
  const isFacetResultPage = useProductResultPage((x) => x.isFacetResultPage);
  const engineFitmentChecksEnabled = useFeatureFlag("PRP_New_Engine_Fitment_Checks")
  const fitmentDetailsFromFitmentFacet = useUserSpecificProductDataStore((x) => x.fitmentDetailsFromFitmentFacet);
  const fitmentDetailsFromSelectedFacets = useUserSpecificProductDataStore((x) => x.fitmentDetailsFromSelectedFacets)
  const fitmentDetails = engineFitmentChecksEnabled ? fitmentDetailsFromSelectedFacets : fitmentDetailsFromFitmentFacet || {}
  const { isVehicleSelected, vehicleName } = useVehicleSidebar(selectedFacets, fitmentDetails);

  const canAdd = showAddToGarage(customerProjects, fitmentDetails);
  const canUpdate = showUpdateCustomerProject(customerProjects, fitmentDetails)

  const OpenSidebarDrawer = usePrpStore((x) => x.OpenSidebarDrawer);
  const openAddOrSelectCustomerProjectExperience = useVehicleStore(
    (state) => state.openAddOrSelectCustomerProjectExperience
  );
  const selectCustomerProject = useVehicleStore(
    (state) => state.selectCustomerProject
  );
  const CloseSidebarDrawer = usePrpStore((x) => x.CloseSidebarDrawer);
  const { dispatch: navigationDispatch } = useContext(NavigationContext);
  const addCustomerProject = useVehicleStore((x) => x.addCustomerProject);
  const updateCustomerProjectData = useVehicleStore((x) => x.updateCustomerProjectData)
  const { PushNotification } = useContext(NotificationContext);

  const customerProjectDrawerEnabled = useFeatureFlag(
    "FrontEnd_CustomerProject_Drawer_Enabled"
  );

  if (skeleton) {
    return <StickyHeaderSkeleton />;
  }
  if (isNoResultPage) {
    return false;
  }

  return (
    <div
      className={ClassNames(
        styles.stickyHeaderContainer,
        classNames,
        isFacetResultPage ? styles.forFrp : false
      )}
    >
      <Button
        brand={"primary"}
        size={"small"}
        className={styles.stickyHeaderButton}
        segmentEvent={createClickedSegmentEvent("PrpStickyHeaderSortAndFilter")}
        testingName={"filterAndSortStickyHeader"}
        onClick={() => OpenSidebarDrawer()}
      >
        <Svg icon="filter-sort" size={1.25} className={styles.filterIcon}></Svg>
        <Typography size={0.875} font={"bold"}>{`Sort & Filter`}</Typography>
      </Button>
      {!isVehicleSelected && (
        <Button
          brand={"secondary"}
          size={"small"}
          className={styles.stickyHeaderButton}
          segmentEvent={createClickedSegmentEvent("SelectVehicleStickyHeader")}
          testingName={"selectVehicleStickyHeader"}
          onClick={() =>
            customerProjectDrawerEnabled
              ? navigationDispatch({
                  type: "setCustomerProjectDrawerState",
                  payload: {
                    garageState: {
                      isCustomerProjectDrawerOpen: true,
                    },
                  },
                })
              : openAddOrSelectCustomerProjectExperience(
                  true,
                  CloseSidebarDrawer
                )
          }
        >
          <Typography size={0.875} font={"bold"}>
            Select Vehicle
          </Typography>
        </Button>
      )}
      {canUpdate && (
        <Button
          isWaiting={loading}
          brand={"secondary"}
          size={"small"}
          className={styles.stickyHeaderButton}
          segmentEvent={createClickedSegmentEvent("UpdateGarageStickyHeader")}
          testingName={"updateGarageStickyHeader"}
          onClick={() => updateCustomerProject(fitmentDetails, canUpdate, setLoading, selectCustomerProject, updateCustomerProjectData, PushNotification, vehicleName)}
        >
          <Typography size={0.875} font={"bold"}>Update Garage</Typography>
        </Button>
      )}
       {!canUpdate && canAdd && (
        <Button
          isWaiting={loading}
          brand={"secondary"}
          size={"small"}
          className={styles.stickyHeaderButton}
          segmentEvent={createClickedSegmentEvent("AddToGarageStickyHeader")}
          testingName={"addVehicleStickyHeader"}
          onClick={() => addToGarage(fitmentDetails, setLoading, selectCustomerProject, addCustomerProject, PushNotification, vehicleName)}
        >
          <Typography size={0.875} font={"bold"}>Add to Garage</Typography>
        </Button>
      )}
    </div>
  );
};

export default StickyHeaderContent;
