import Divider from "Clutch/Atoms/Divider";
import styles from '../sidebar.module.scss'

const SideBarBlockWrapper = ({ children, dividerTop, dividerBottom }) => {

    if(!children)
        return false

    return (
        <>
            {dividerTop ? <Divider className={styles.divider} /> : null}

            <div className={styles.container}>
                {children}
            </div>
            {dividerBottom ? <Divider className={styles.divider} /> : null}

        </>
    )
}

export default SideBarBlockWrapper