import {useState} from 'react';
import Area from 'Clutch/Molecules/Area';
import Typography from 'Clutch/Atoms/Typography';
import Popover from 'Clutch/Components/Popover';
import styles from './productBoostingRules.module.scss';
import Card from 'Clutch/Atoms/Card';

const ProductBoostingRules = ({boostingRules, productRecalls}) => {
    const [openBoost, setOpenBoost] = useState(null);

    try {
        const enhancedBoostingRules = boostingRules.map(boost => {
            const matchingRecall = productRecalls.find(recall => recall.boostingPosition === boost.boostPositions[0]);
            if (matchingRecall) {
                boost.boostName = `Product Recall Rule (ID: ${matchingRecall.searchRecallId})`;
            }
            return boost;
        });

        return (
            <Card noMargin noPadding className={styles.card}>
                <div className={styles.rulesWrapper}>
                    {enhancedBoostingRules && enhancedBoostingRules.length > 0
                        ? (enhancedBoostingRules.map((boost, index) => (
                            <div key={index}>
                                <Popover
                                    content={<Typography size={1}>
                                        {boost.appliedBoostingConfiguration}
                                    </Typography>}
                                    isOpen={openBoost === index}
                                    onToggleMenu={(x) => setOpenBoost(x === true ? index : null)}
                                >
                                    <Area
                                        brand={"secondary"}
                                        className={styles.rule}
                                        title={boost.boostName}
                                    >
                                        <Typography size={0.75}>
                                            Rule: {boost.boostName}
                                            {boost.boostPositions && boost.boostPositions.length > 0 && (
                                                <>
                                                    <br/>
                                                    <span className={styles.pinnedPositions}>
                                                        Pinned Position(s):{' '}
                                                        {boost.boostPositions.map(pos => pos + 1).join(', ')}
                                                    </span>
                                                </>
                                            )}
                                        </Typography>
                                    </Area>
                                </Popover>
                            </div>)))
                        : (
                            <Area className={styles.rule}>
                                <Typography size={0.75}>
                                    No Boosting Rules Applied
                                </Typography>
                            </Area>)}
                </div>
            </Card>
        );
    } catch (err) {
        console.error(err);
        return null;
    }
};

export default ProductBoostingRules;