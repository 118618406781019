import getEngineIds from "Clutch/Organisms/CustomerProjectDrawer/Utilities/getEngineIds";

export const showAddToGarage = (customerProjects, fitmentDetails) => {
  if (!fitmentDetails?.makeId && !fitmentDetails?.raceTypeId && !fitmentDetails?.engineManufacturerId) 
    return false;
  
  const matchingProject = customerProjects?.find(
    (project) =>
      project.year == fitmentDetails?.year &&
      project.makeId == fitmentDetails?.makeId &&
      project.modelId == fitmentDetails?.modelId &&
      project.raceTypeId == fitmentDetails?.raceTypeId &&
      project.engineManufacturerId == fitmentDetails?.engineManufacturerId &&
      project.engineMakeId == fitmentDetails?.engineMakeId &&
      project.engineSeriesId == fitmentDetails?.engineSeriesId &&
      project.engineConfigurationId == fitmentDetails?.engineConfigurationId &&
      project.engineQualifierId == fitmentDetails?.engineQualifierId &&
      project.engineVersionId == fitmentDetails?.engineVersionId &&
      project.engineDisplacementId == fitmentDetails?.engineDisplacementId &&
      project.engineCodeId == fitmentDetails?.engineCodeId &&
      project.engineCamshaftTypeId == fitmentDetails?.engineCamshaftTypeId &&
      project.engineFuelSystemId == fitmentDetails?.engineFuelSystemId
  );
  return !matchingProject;
};

export const showUpdateCustomerProject = (customerProjects, fitmentDetails) => {
  if (!customerProjects || (!fitmentDetails?.makeId && !fitmentDetails?.raceTypeId && !fitmentDetails?.engineManufacturerId)) 
    return false;

  if (
    ((fitmentDetails.year && fitmentDetails.makeId && fitmentDetails.modelId) ||
      fitmentDetails.raceTypeId) &&
    fitmentDetails.engineManufacturerId
  ) {
    const matchingProject = customerProjects?.find(
      (project) =>
        project.year == fitmentDetails?.year &&
        project.makeId == fitmentDetails?.makeId &&
        project.modelId == fitmentDetails?.modelId &&
        project.raceTypeId == fitmentDetails?.raceTypeId &&
        project.engineManufacturerId === null
    );
    const existingUpdatedProject = customerProjects?.find(
      (project) => 
        project.year == fitmentDetails?.year &&
        project.makeId == fitmentDetails?.makeId &&
        project.modelId == fitmentDetails?.modelId &&
        project.raceTypeId == fitmentDetails?.raceTypeId &&
        project.engineManufacturerId == fitmentDetails?.engineManufacturerId &&
        project.engineMakeId == fitmentDetails?.engineMakeId &&
        project.engineSeriesId == fitmentDetails?.engineSeriesId &&
        project.engineConfigurationId == fitmentDetails?.engineConfigurationId &&
        project.engineQualifierId == fitmentDetails?.engineQualifierId &&
        project.engineVersionId == fitmentDetails?.engineVersionId &&
        project.engineDisplacementId == fitmentDetails?.engineDisplacementId &&
        project.engineCodeId == fitmentDetails?.engineCodeId &&
        project.engineCamshaftTypeId == fitmentDetails?.engineCamshaftTypeId &&
        project.engineFuelSystemId == fitmentDetails?.engineFuelSystemId
    )
    if (!existingUpdatedProject && matchingProject) 
      return matchingProject
    return false
  }
};

export const addToGarage = async (
  fitmentDetails,
  setLoading,
  selectCustomerProject,
  addCustomerProject,
  pushNotification,
  vehicleName
) => {
  try {
    setLoading(true);
    const engineIds = getEngineIds(
      !!fitmentDetails?.engineManufacturerId,
      fitmentDetails
    );

    const response = await addCustomerProject({
      year: fitmentDetails?.year,
      makeId: fitmentDetails?.makeId,
      modelId: fitmentDetails?.modelId,
      raceTypeId: fitmentDetails?.raceTypeId,
      engineIds: engineIds,
    });

    const selectedProject = response.projects.find(
      (project) =>
        project.year == fitmentDetails?.year &&
        project.makeId == fitmentDetails?.makeId &&
        project.modelId == fitmentDetails?.modelId &&
        project.raceTypeId == fitmentDetails?.raceTypeId &&
        project.engineManufacturerId == engineIds?.engineManufacturerId &&
        project.engineMakeId == engineIds?.engineMakeId &&
        project.engineSeriesId == engineIds?.engineSeriesId &&
        project.engineConfigurationId == engineIds?.engineConfigurationId &&
        project.engineQualifierId == engineIds?.engineQualifierId &&
        project.engineVersionId == engineIds?.engineVersionId &&
        project.engineDisplacementId == engineIds?.engineDisplacementId &&
        project.engineCodeId == engineIds?.engineCodeId &&
        project.engineCamshaftTypeId == engineIds?.engineCamshaftTypeId &&
        project.engineFuelSystemId == engineIds?.engineFuelSystemId
    );

    await selectCustomerProject(selectedProject.projectId, null);

    pushNotification(
      `${vehicleName} added to your garage!`,
      "positive",
      5000,
      null,
      {
        intent: "positive",
        icon: "check",
      }
    );
    setLoading(false);
  } catch (err) {
    setLoading(false);
    pushNotification(`Something went wrong, please try that again`, "negative");
  }
};

export const updateCustomerProject = async (
  fitmentDetails,
  projectToUpdate,
  setLoading,
  selectCustomerProject,
  updateCustomerProjectData,
  pushNotification,
  vehicleName
) => {
  try {
    setLoading(true);
    const engineIds = getEngineIds(true, fitmentDetails);

    const response = await updateCustomerProjectData({
      year: projectToUpdate.year,
      makeId: projectToUpdate.makeId,
      modelId: projectToUpdate.modelId,
      raceTypeId: projectToUpdate.raceTypeId,
      engineIds,
      vehicleBaseId: projectToUpdate.vehicleBaseId,
      customerProjectId: projectToUpdate.projectId,
    });

    if (!response || !response.success) {
      throw "updateCustomerProjectData failed";
    }

    await selectCustomerProject(projectToUpdate.projectId, null);

    pushNotification(
      `${vehicleName} updated in your garage!`,
      "positive",
      5000,
      null,
      {
        intent: "positive",
        icon: "check",
      }
    );
    setLoading(false);
  } catch (err) {
    setLoading(false);
    pushNotification(`Something went wrong, please try that again`, "negative");
  }
};
