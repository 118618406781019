import React, { useRef, useContext, useEffect } from "react";
import Modal from "Components/Modal/ModalWithPortal.js";
import { usePrpStore } from "../../contexts/prpContext";
import RecommendationSection from "Clutch/Organisms/RecommendationSection/RecommendationSection";
import { RecommendationProvider } from "Clutch/Stores/RecommendationStore/RecommendationStore";
import { AddToCartExperienceContext } from "Contexts/AddToCartExperienceContext/AddToCartExperienceContext";
import ErrorBoundary from "Clutch/UtilityComponents/ErrorBoundary";
import styles from "./compareToolModal.module.scss"
import useProductResultPage from "../../hooks/useProductResultPage";

const CompareToolModal = () => {
  const modal = useRef(null);
  const compareToolModal = usePrpStore(x => x.uiState.compareToolModal);
  const CloseCompareToolModal = usePrpStore(x => x.CloseCompareToolModal);

  useEffect(() => {
    if (compareToolModal){
      modal.current.open()
    } 
  }, [compareToolModal]);

  const {
    isVariantDetailsDrawerOpen,
    isKitDetailsDrawerOpen,
    isAtcDrawerOpen,
  } = useContext(AddToCartExperienceContext);

  useEffect(() => {
    if (isVariantDetailsDrawerOpen || isKitDetailsDrawerOpen || isAtcDrawerOpen){
      modal.current.close()
      CloseCompareToolModal()
    }
  }, [isVariantDetailsDrawerOpen, isKitDetailsDrawerOpen, isAtcDrawerOpen]);

  return (
    <div>
      <Modal
        ref={modal}
        outsideClick
        id="compareToolModal"
        customOnClose={CloseCompareToolModal}
      >
        <CompareToolContent />
      </Modal>
    </div>
  );
};

const CompareToolContent = () => {
  const skuBaseToCompare = useProductResultPage(x => x.skuBaseToCompare)

  return (
    <ErrorBoundary>
      <div className={styles.compareToolWrapper}>
        <RecommendationProvider
          sections={["PRP_Compare"]}
          skuBaseNumbers={[skuBaseToCompare] || []}
        >
          <RecommendationSection
            section={"PRP_Compare"}
            displayType={"Comparison"}
            numberOfSkeletons={4}
            numberOfSkeletonSections={1}
            skeletonType={"comparison"}
            displayNoRecMessage
          />
        </RecommendationProvider>
      </div>
    </ErrorBoundary>
  );
};

export default CompareToolModal;
