import DefaultPageSize from '/constants/pageSize'

const getNumProductsToBeLoaded = (totalProductCount, currentPage) => {
    let shownResultsCount = currentPage * DefaultPageSize;

    let loadMoreCount = (shownResultsCount + DefaultPageSize) > totalProductCount ?
        (totalProductCount - shownResultsCount) :
        DefaultPageSize

    return loadMoreCount
}

export {getNumProductsToBeLoaded}