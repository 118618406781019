import { StorageAccessor } from 'Clutch/Utilities/LocalStorageUtility'

const key = 'prpSideBarOpen'
const minutesToExpire = 0.167 // 10s

const setSidebarOpen = () => {
    try {
        StorageAccessor.sessionStorage.setObj(key, true, minutesToExpire)
    }
    catch (err) {
        console.error(err)
    }

}

const setSidebarClose = () => {
    try {
        StorageAccessor.sessionStorage.setObj(key, false, minutesToExpire) 
    }
    catch (err) {
        console.error(err)
    }

}

const getSidebarOpen = () => {
    try {
        return StorageAccessor.sessionStorage.getObj(key)
    }
    catch (err) {
        console.error(err)
    }

}

export { setSidebarOpen, getSidebarOpen, setSidebarClose }