import React, { useContext, useEffect, useState } from 'react'


import Form from 'Atoms/Form'
import styles from './facetSearchBar.module.scss'
import { usePrpStore } from '../../../../contexts/prpContext'
import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag';
import FacetOption from '../facetOption/facetOption';

const FacetSayt = ({ facet, children, setIsWaiting }) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [searchResults, setSearchResults] = useState([])
    const GetAllFacetLinks = usePrpStore(x => x.GetAllFacetLinks)
    const spaLoading = usePrpStore(x => x.uiState.spaLoading)



    let facetSearchBarFlag = useFeatureFlag("Facet_Search_Bar");

    const displayTitle = facet.facetName.endsWith('s') || facet.facetName.endsWith('y') || facet.facetName.endsWith('h') ? facet.facetName : `${facet.facetName}s`

    const handleOnFocus = () => {
        if (!facet.isShowMoreHydrated && facet.hasShowMore)
            GetAllFacetLinks(() => { }, () => { });
    }

    if (typeof window != 'undefined') {
        window.addEventListener('popstate', (event) => {
            if (document.getElementById(`${facet.facetName}_search_bar`))
                document.getElementById(`${facet.facetName}_search_bar`).value = ""
            setSearchTerm("")
        });
    }

    useEffect(() => { doSearch(searchTerm) }, [facet])

    useEffect(() => {
        if (spaLoading) {
            if (document.getElementById(`${facet.facetName}_search_bar`))
                document.getElementById(`${facet.facetName}_search_bar`).value = ""
            setSearchTerm("")
        }
        if (!spaLoading)
            setIsWaiting(false)
    }, [spaLoading])

    const doSearch = (term) => {
        term = term.trim();
        setSearchTerm(term);
        if (!term.length)
            return;
        var list = facet.searchResponseFacetOptions.filter(x => checkRegex(x, term))
        list = list?.sort((a, b) => b?.displayValue?.toLowerCase().startsWith(term) - a?.displayValue?.toLowerCase().startsWith(term.toLowerCase()))
        setSearchResults((list ?? []).slice(0, 6));
    }

    const checkRegex = (option, searchTerm) => {
        var regex = new RegExp(`(${searchTerm})+`, 'i')
        var match = option.displayValue.match(regex)
        return match?.[0]
    }

    const handleChange = (e) => {
        e.preventDefault();
        const term = e.nativeEvent.srcElement.value;
        doSearch(term)
    }



    const searchBar = facetSearchBarFlag != false && facet.hasShowMore && <div className={styles.facetSearchBar}>
        <Form.Textbox id={`${facet.facetName}_search_bar`} fill placeholder={`Search ${displayTitle}`} data-testid={`${facet.facetName}_search_bar_input`} onClick={handleOnFocus} onChange={handleChange} /><br />
    </div>

    if (!searchTerm.length) {
        return <>
            {searchBar}
            {children}
        </>
    }

    return (
        <>
            {searchBar}
            {searchResults.map((option, i) => {
                return (
                    <FacetOption facetName={facet.facetName} setIsLoading={setIsWaiting} isMultiSelect={facet.isMultiSelect} key={`facet_option_${facet.facetName}_${option.id}_${i}`} facetOption={option} />
                )
            })}
        </>
    )
}

export default FacetSayt