import { StorageAccessor } from 'Clutch/Utilities/LocalStorageUtility'

const key = 'prpLastPageVisited'

const setLastPageVisited = async (skuNumber) => {
    try {
        StorageAccessor.localStorage.setObj(key, {url: window.location.href, skuNumber: skuNumber})
    }
    catch (err) {
        console.error('There was an error setting the last page visited', err)
    }
}

const getLastPageVisited = () => {
    try {
        const lastPageVisited = StorageAccessor.localStorage.getObj(key)

        return lastPageVisited
    }
    catch (err) {
        console.error('There was an error getting the last page visited', err)
    }
}

export { setLastPageVisited, getLastPageVisited}