import React, {useState} from 'react';
import Area from 'Clutch/Molecules/Area';
import Typography from 'Clutch/Atoms/Typography';
import Popover from 'Clutch/Components/Popover';
import Card from 'Clutch/Atoms/Card';
import styles from './diversificationRules.module.scss';

const DiversificationRules = ({diversification}) => {
    const [isOpen, setIsOpen] = useState(false);

    try {
        const hasDiversification = diversification && diversification.length > 0;
        let titleContent = 'No Diversification Rules Applied';

        if (hasDiversification) {
            titleContent = diversification
                .map(
                    (rule) =>
                        `${rule.key}: Minimum Distance ${rule.value.minimum_distance}`
                )
                .join('\n');
        }

        const handleMouseEnter = () => {
            if (hasDiversification) {
                setIsOpen(true);
            }
        };

        const handleMouseLeave = () => {
            setIsOpen(false);
        };

        const areaContent = (
            <Area
                className={styles.rule}
                title={titleContent}
                intent={hasDiversification ? 'positive' : undefined}
            >
                <Typography size={0.75}>
                    {hasDiversification
                        ? 'Diversification'
                        : 'No Diversification Rules Applied'}
                </Typography>
            </Area>
        );

        return (
            <Card noMargin noPadding className={styles.card}>
                <div
                    className={styles.rulesWrapper}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {hasDiversification ? (
                        <Popover
                            content={
                                <div className={styles.popoverContent}>
                                    {diversification.map((rule, index) => (
                                        <Typography key={index} size={1}>
                                            <strong>{rule.key}</strong>: Minimum Distance{' '}
                                            {rule.value.minimum_distance}
                                        </Typography>
                                    ))}
                                </div>
                            }
                            isOpen={isOpen}
                        >
                            {areaContent}
                        </Popover>
                    ) : (
                        areaContent
                    )}
                </div>
            </Card>
        );
    } catch (err) {
        console.error(err);
        return null;
    }
};

export default DiversificationRules;