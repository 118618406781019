
import { useState, useContext, useEffect } from 'react'
import { useUserStore } from "Clutch/Stores/UserStore/UserStore";
import Typography from 'Clutch/Atoms/Typography'
import Svg from 'Clutch/Atoms/Svg'
import useFeatureFlag from 'Clutch/Hooks/useFeatureFlag/useFeatureFlag';
import styles from '../sidebar.module.scss'
import TextLink from 'Clutch/Atoms/TextLink'
import Form from 'Clutch/Atoms/Form'
import Button from 'Clutch/Molecules/Button'
import { validateZip } from 'Clutch/Utilities/Location/Location'
import Skeleton from 'Clutch/Atoms/Skeleton'
import SideBarBlockWrapper from '../shared/sideBarBlockWrapper';
import { createClickedSegmentEvent } from 'Clutch/Utilities/Instrumentation/Impressions/impressionSegmentEvents'

const ZipCodeInput = ({ dividerTop = false, dividerBottom = false }) => {
    const zipCode = useUserStore((x) => x.context.zipCode);
    const userGuid = useUserStore((x) => x.context.userGuid);
    const updateUserZipCode = useUserStore((x) => x.updateUserZipCode);

    const [enteringZip, setEnteringZip] = useState(false);
    const [error, setError] = useState(null);

    let shippingEstimateFlag = useFeatureFlag('PRP_FrontEnd_ShippingEstimates');
    if (shippingEstimateFlag == false) return false;

    if (!shippingEstimateFlag) return <SideBarBlockWrapper dividerTop={dividerTop} dividerBottom={dividerBottom}><Skeleton fullWidth height={16} /></SideBarBlockWrapper>


    const submit = (e) => {
        e.preventDefault()
        const value = e.nativeEvent?.target[0]?.value?.trim() ?? null;
        if (validateZip(value)) {
            updateUserZipCode(value)
            setEnteringZip(false);
            setError(null)
        } else {
            setError("Please enter a 5 digit ZIP code")
        }
    }

    let contents = null;

    if (enteringZip) {
        contents = (<>
            <Form onSubmit={submit} className={styles.form}>
                <div className={styles.container}>
                    <Form.Textbox hideSpinner autoFocus type={'number'} fill></Form.Textbox>
                    <Button size={'small'} className={styles.switchLabel} invert intent={'action'} type={'submit'} segmentEvent={createClickedSegmentEvent('SubmitZipCodePrp')}>
                        <Svg icon={"check"} size={1} />
                    </Button>
                </div>
                <Typography size={0.875} intent={'negative'}>{error}</Typography>
            </Form>
        </>)
    } else if (!zipCode) {
        contents = (<>
            <Svg icon={"trackorder"} size={1} /> <TextLink intent={'action'} onClick={() => setEnteringZip(true)} className={styles.switchLabel} size={0.875}>Get Shipping Estimates</TextLink>
        </>)
    } else {
        contents = (<>

            <TextLink intent={'action'} className={styles.container} onClick={() => { setEnteringZip(true) }} size={0.875}>
                <Svg icon={"trackorder"} size={1} />
                <Typography intent={'action'} className={styles.switchLabel} size={0.875}>Shipping to {zipCode}</Typography>
            </TextLink>
        </>)
    }

    return (
        <SideBarBlockWrapper dividerTop={dividerTop} dividerBottom={dividerBottom}>
            {contents}
        </SideBarBlockWrapper>
    )
}



export default ZipCodeInput