import useProductResultPage from "../../hooks/useProductResultPage";
import styles from "./shopAllFacets.module.scss";
import LandingPageLinkRack from "Clutch/Organisms/LandingPageLinkRack"
import Typography from "Clutch/Atoms/Typography";

const ShopAllFacets = () => {

  const shopAllFacets = useProductResultPage(x => x.shopAllFacets)
  const seoLinks = useProductResultPage(x => x.seoLinks)


  const formatData = (shopAllFacet) => {
    let links = [];
    for (let i = 0; i < shopAllFacet.facetOptions.length; i++) {
      links.push({
        displayValue: shopAllFacet.facetOptions[i].name,
        url: shopAllFacet.facetOptions[i].url,
        imageUrl: shopAllFacet.facetOptions[i].imageUrl
      })
    }
    return links;
  }
  return (
    <div className={styles.facetContainer}>

      {shopAllFacets?.map((shopAllFacet, i) => {
        return <div className={styles.facetBlock} key={shopAllFacet.displayName}>
          <Typography
            as={"h2"}
            font={"bold"}
            size={1.25}
            className={styles.title}
          >
            Popular {shopAllFacet.displayName}
          </Typography>
          <LandingPageLinkRack 
            links={formatData(shopAllFacet)} 
            showMoreLink={{ text: `See all ${shopAllFacet.displayName}`, url: shopAllFacet.siteMapUrl }}
            isBrandImage={shopAllFacet.displayName.toLowerCase() == 'brands'}
            isRaceTypeImage={shopAllFacet.displayName.toLowerCase() == 'race types'} 
            dataTestId={shopAllFacet.displayName}/>
        </div>
      })}
      {
        seoLinks?.length ? 
        <div className={styles.facetBlock} key={'popularPages'}>
          <Typography
            as={"h2"}
            font={"bold"}
            size={1.25}
            className={styles.title}
          >
            Popular Pages
          </Typography>
          <LandingPageLinkRack 
            links={seoLinks.map(x => { return {displayValue: x.linkText, url: x.url}})} 
            dataTestId={'shopAllSeoLinks'}/>
        </div> : false
      }  
    </div>
  );
};

export default ShopAllFacets;
